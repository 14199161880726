import Enum from "@utils/enum";
import { signout } from "reducers/auth";

export const DROPDOWN_MENU_ITEMS = [
  {
    src: "/static/svg/Shop_light_gray.svg",
    name: "Company Profile",
    href: "/my-company-profile",
    type: "item",
  },
  // {
  //   src: "/static/svg/Arhive_light_gray.svg",
  //   name: "My Products",
  //   href: "/my-products",
  //   type: "item",
  // },
  // {
  //   src: "/static/svg/Donations_gray.svg",
  //   name: "Donations",
  //   href: "/my-donations/my-donation-listings",
  //   type: "item",
  // },
  {
    src: "/static/svg/Bag_light_gray.svg",
    name: "Purchases",
    href: "/purchases/orders-placed",
    type: "item",

  },
  // {
  //   src: "/static/svg/Bag_light_gray.svg",
  //   name: "Orders",
  //   href: "/purchases/orders-placed",
  //   type: "item",
  //   isChildren:true
  // },
  // {
  //   src: "/static/svg/Bag_light_gray.svg",
  //   name: "Allocations",
  //   href: "/purchases/surplus-orders",
  //   type: "item",
  //   isChildren:true
  // },
  
  {
    src: "/static/svg/Chart_alt_light_gray.svg",
    name: "Sales",
    href: "/sales/orders-received",
    type: "item",
   
  },
  {
    src: "/static/svg/File_dock_light.svg",
    name: "Remittance Reports",
    href: "/remittance-reports",
    type: "item",
   
  },
  {
    src: "/static/svg/info_gray.svg",
    name: "Service Fee Invoices",
    href: "/service-fee-invoices",
    type: "item",
 
  },
  // {
  //   src: "/static/svg/Bag_light_gray.svg",
  //   name: "Orders",
  //   href: "/sales/orders-received",
  //   type: "item",
  //   isChildren:true
  // },
  // {
  //   src: "/static/svg/Bag_light_gray.svg",
  //   name: "Allocations",
  //   href: "/new-surplus-allocation",
  //   type: "item",
  //   isChildren:true
  // },
  // {
  //   src: "/static/svg/Bag_light_gray.svg",
  //   name: "Remittance Reports",
  //   href: "/remittance-reports",
  //   type: "item",
  //   isChildren:true
  // },
  // {
  //   src: "/static/svg/recently-viewed_gray.svg",
  //   name: "Recently Viewed Produces",
  //   href: "/recently-viewed-produces",
  //   type: "item",
  // },

  // {
  //   src: "/static/svg/Bell_light.svg",
  //   name: "Notifications",
  //   href: "/notifications",
  //   type: "item",
  // },

  // {
  //   src: "/static/svg/Setting_line_light_gray.svg",
  //   name: "Settings",
  //   href: "/settings",
  //   type: "item",
  // },
  // {
  //   src: "/static/svg/Dell_light.svg",
  //   name: "Account Deletion Request",
  //   href: "/account-deletion-request",
  //   type: "item",
  // },
  {
    key: "logout",
    src: "/static/svg/Sign_out_squre_light_gray.svg",
    name: "Log Out",
    href: "/logging-out",
  },
];

export const MENU_ITEMS = [
  /* {
    src: "/static/svg/Arhive_light.svg",
    name: "Company Profile",
    href: "/my-company-profile",
    type: "collapse",
    children: [
      {
        src: "/static/svg/ic_company_profile.svg",
        activeSrc: "/static/svg/ic_company_profile_active.svg",
        name: "My Company Profile",
        href: "/my-company-profile",
        type: "collapse",
      },
      {
        src: "/static/svg/ic_company_profile.svg",
        activeSrc: "/static/svg/ic_company_profile_active.svg",
        name: "Integrations",
        href: "/integrations",
        type: "collapse",
      },
      {
        src: "/static/svg/ic_colleagues.svg",
        activeSrc: "/static/svg/ic_colleagues_active.svg",
        name: "My Colleagues",
        href: "/my-company/my-colleagues",
        type: "collapse",
      },
      {
        src: "/static/svg/ic_company_products.svg",
        activeSrc: "/static/svg/ic_company_products_active.svg",
        name: "My Company Products",
        href: "/my-company/products",
        type: "collapse",
      },
      // {
      //   src: "/static/svg/ic_subscriptions.svg",
      //   activeSrc: "/static/svg/ic_subscriptions_active.svg",
      //   name: "Subscriptions",
      //   href: "/my-company/subscriptions",
      //   type: "collapse",
      // },
      {
        src: "/static/svg/Address Managment.svg",
        activeSrc: "/static/svg/Address Managment.svg",
        name: "Address Management",
        href: "/manage-addresses",
        type: "collapse",
      },
    ],
  }, */
  /* {
    src: "/static/svg/User_add_light.svg",
    name: "My Connections",
    href: "/my-connections",
    type: "item",
  }, */
  {
    name: "My Profile",
    href: "/my-user-profile",
    type: "item",
  },
  {
    name: "Company Profile",
    href: "/my-company-profile",
    type: "item",
  },
  {
    name: "Address Management",
    href: "/manage-addresses",
    type: "item",
  },
  {
    name: "Colleagues",
    href: "/my-company/my-colleagues",
    type: "item",
  },
  {
    name: "My Connections",
    href: "/my-connections",
    type: "item",
  },

  {
    name: "Trading Partners",
    href: "/trading-partners",
    type: "item",
  },
  {
    name: "Products",
    href: "/my-products/my-product-listings",
    type: "item",
  },
  {
    name: "My Demands",
    href: "/my-products/my-demanded-listings",
    type: "item",
  },
  {
    name: "Donations",
    href: "/my-donations/my-donation-listings",
    type: "item",
  },
  /* {
    src: "/static/svg/Arhive_light.svg",
    name: "My Products",
    href: "/my-products",
    type: "collapse",
    children: [
      {
        src: "/static/svg/ic_products.svg",
        activeSrc: "/static/svg/ic_products.svg",
        name: "My Product Listings",
        href: "/my-products/my-product-listings",
        type: "collapse",
      },
      // {
      //   src: "/static/svg/ic_products.svg",
      //   activeSrc: "/static/svg/ic_products.svg",
      //   name: "Pending Product Requests",
      //   href: "/pending-produce-requests",
      //   type: "collapse",
      // },
      {
        src: "/static/svg/Search_alt_light.svg",
        activeSrc: "/static/svg/Search_alt_light.svg",
        name: "My Demanded Listings",
        href: "/my-products/my-demanded-listings",
        type: "collapse",
      },
      {
        name: "Donations",
        href: "/my-donations/my-donation-listings",
        type: "collapse",
      },
    ],
  },

  {
    src: "/static/svg/Search_alt_light.svg",
    name: "Demands",
    href: "/my-products/my-demanded-listings",
    type: "item",
    hideOnSidebar: true,
  },
  {
    src: "/static/svg/Donations.svg",
    name: "Donations",
    href: "/my-donations/my-donation-listings",
    type: "item",
    hideOnSidebar: true,
  }, */

  {
 
    name: "Purchases",
    href: "/purchases/orders-placed?baseTab=buy",
    type: "collapse",
    children: [
      {
        src: "/static/svg/ic_orders.svg",
        activeSrc: "/static/svg/ic_orders_active.svg",
        name: "Orders",
        href: "/purchases/orders-placed",
        type: "collapse",
      },
      {
        src: "/static/svg/ic_invoice.svg",
        activeSrc: "/static/svg/ic_invoice_active.svg",
        name: "Invoices",
        href: "/purchases/invoices-received",
        type: "collapse",
      },
      // {
      //   src: "/static/svg/ic_invoice.svg",
      //   activeSrc: "/static/svg/ic_invoice_active.svg",
      //   name: "Allocation",
      //   href: "/purchases/surplus-orders",
      //   type: "collapse",
      // },
      // {
      //   src: "/static/svg/ic_invoice.svg",
      //   activeSrc: "/static/svg/ic_invoice_active.svg",
      //   name: "Credit Notes",
      //   href: "/credit-notes-buyer",
      //   type: "collapse",
      // },

      // {
      //   src: "/static/svg/ic_invoice.svg",
      //   activeSrc: "/static/svg/ic_invoice_active.svg",
      //   name: "Service Fee Invoices",
      //   href: "/service-fee-invoices",
      //   type: "collapse",
      // },

      // {
      //   src: "/static/svg/ic_statements.svg",
      //   activeSrc: "/static/svg/ic_statements_active.svg",
      //   name: "Statements",
      //   href: "/purchases/statements",
      //   type: "item",
      //   disabled: true
      // }
    ],
  },
  {
 
    name: "Sales",
    href: "/sales/orders-received?baseTab=sell",
    type: "collapse",
    children: [
      {
        src: "/static/svg/ic_orders.svg",
        activeSrc: "/static/svg/ic_orders_active.svg",
        name: "Orders",
        href: "/sales/orders-received",
        type: "collapse",
      },
      {
        src: "/static/svg/ic_invoice.svg",
        activeSrc: "/static/svg/ic_invoice_active.svg",
        name: "Invoices",
        href: "/sales/invoices-issued",
        type: "collapse",
      },
      // {
      //   src: "/static/svg/ic_invoice.svg",
      //   activeSrc: "/static/svg/ic_invoice_active.svg",
      //   name: "Credit Notes",
      //   href: "/credit-notes",
      //   type: "collapse",
      // },
      {
        src: "/static/svg/ic_invoice.svg",
        activeSrc: "/static/svg/ic_invoice_active.svg",
        name: "Pending Requests",
        href: "/pending-surplus-requests",
        type: "collapse",
      },
      // {
      //   src: "/static/svg/ic_invoice.svg",
      //   activeSrc: "/static/svg/ic_invoice_active.svg",
      //   name: "Pending Offers",
      //   href: "/pending-sales",
      //   type: "collapse",
      // },
      {
        src: "/static/svg/ic_invoice.svg",
        activeSrc: "/static/svg/ic_invoice_active.svg",
        name: "New Allocation",
        href: "/new-surplus-allocation",
        type: "collapse",
      },
   

      // {
      //   src: "/static/svg/ic_statements.svg",
      //   activeSrc: "/static/svg/ic_statements_active.svg",
      //   name: "Statements",
      //   href: "/sales/statements",
      //   type: "item",
      //   disabled: true
      // }
    ],
  },
  /* {
    src: "/static/svg/Order_light.svg",
    name: "Company Directory",
    href: "/company-directory",
    type: "item",
  },
  {
    src: "/static/svg/Bell_light.svg",
    name: "Notifications",
    href: "/notifications",
    type: "item",
  },
  {
    src: "/static/svg/recently-viewed 2.svg",
    name: "Recently Viewed Produces",
    href: "/recently-viewed-produces",
    type: "item",
  },
  {
    src: "/static/svg/Setting_line_light.svg",
    name: "Settings",
    href: "/settings",
    type: "item",
  }, */
  {
    name: "Remittance Reports",
    href: "/remittance-reports",
    type: "item",
  },
  {
    name: "Service Fee Invoices",
    href: "/service-fee-invoices",
    type: "item",
  },
  {
    name: "Recently Viewed Produces",
    href: "/recently-viewed-produces",
    type: "item",
  },
  {
    name: "Credit History",
    href: "/my-credit-history",
    type: "item",
  },
  {
    name: "Account Deletion Request",
    href: "/account-deletion-request",
    type: "item",
  },
  {
    name: "Settings",
    href: "/settings",
    type: "item",
  },
 
];

export const MENU_ITEMS_RESTRICTION = [
  {
    key: "profile",
    src: "/static/svg/ic_profile.svg",
    name: "My Profile",
    href: "/my-company/company-profile",
  },
  {
    key: "myColleagues",
    src: "/static/images/icons/ic_mycolleagues.png",
    name: "My Colleagues",
    href: "/my-colleagues",
  },
  {
    key: "notification",
    src: "/static/svg/ic_noti.svg",
    name: "Notifications",
    href: "/notifications",
  },
  {
    key: "settings",
    src: "/static/svg/ic_setting.svg",
    name: "Settings",
    href: "/settings",
  },
  {
    key: "logout",
    src: "/static/svg/ic_logout.svg",
    name: "Log Out",
    callback: (dispatch) => dispatch(signout()),
  },
];

export const PATHNAME_NOT_RESTRICTED = [
  "/my-company/company-profile",
  "/my-colleagues",
  "/notification",
  "/settings",
  "/company-registration",
];

export const NOTIFICATION_ACTION_PARENT_TYPE_ENUM = Enum({
  CUSTOM_MESSAGE:"custom_message",
  CREATE_OFFER: "offer",
  ACCEPT_OFFER: "offer",
  OTHER_ACCEPT_OFFER: "offer",
  OTHER_COUNTER_OFFER: "offer",
  DELIVERY_DATE_CHANGED: "delivery_date",
  COUNTER_ALLOCATING_OFFER: "allocating",
  OTHER_REJECT_OFFER: "offer",
  OTHER_CANCEL_OFFER: "offer",
  DELIVERY_OFFER: "offer",
  RECEIVE_OFFER: "offer",
  CERTIFICATE_DOC_APPROVED: "doc_approve",
  RECEIVE_PARTIAL_OFFER: "offer",
  BUYER_ACCEPTED_INVOICE: "buyer_accept_invoice",
  EDIT_ARRIVAL_OFFER: "offer",
  SUBMIT_ARRIVAL_OFFER: "offer",
  SETTLEMENT_OFFER: "offer",
  REMIND_OFFER_24: "offer",
  NEW_OFFER_REQUEST: "offer",
  REMIND_OFFER_72: "offer",
  CREATE_COMMENT: "offer",
  ACCEPT_INVOICE: "invoice",
  CREATE_INVOICE: "offer",
  REVISE_INVOICE: "invoice",
  REMIND_INVOICE_24: "invoice",
  NEW_INVOICE_REQUEST: "invoice",
  REMIND_INVOICE_72: "invoice",
  SENT_INVOICE: "offer",
  PAID_INVOICE: "offer",
  CREATE_COMMENT: "offer",
  REJECT_INVOICE: "reject_invoice",
  REMIND_CREATE_RECEPTIONS: "offer",
  CREATE_MESSAGE: "CREATE_MESSAGE",
  INVITE_EMPLOYEE_COMPANY: "company",
  ACCEPTED: "connection",
  CREATED: "connection",
  PREMIUM_USER_CONNECTION: "connection",
  COMMENT_FEED: "feed",
  LIKE_FEED: "feed",
  CREATE_ALLOCATING_OFFER: "allocating",
});

export const OFFER_ACTION_TYPE_ENUM = Enum({
  create: "CREATE_OFFER",
  accept: "ACCEPT_OFFER",
  otherAccept: "OTHER_ACCEPT_OFFER",
  otherCounter: "OTHER_COUNTER_OFFER",
  otherReject: "OTHER_REJECT_OFFER",
  otherCancel: "OTHER_CANCEL_OFFER",
  delivery: "DELIVERY_OFFER",
  receive: "RECEIVE_OFFER",
  receivedPartial: "RECEIVE_PARTIAL_OFFER",
  editArrival: "EDIT_ARRIVAL_OFFER",
  submitArrival: "SUBMIT_ARRIVAL_OFFER",
  settlement: "SETTLEMENT_OFFER",
  complete: "COMPLETE_OFFER",
  remind24: "REMIND_OFFER_24",
  remind72: "REMIND_OFFER_72",
  createComment: "CREATE_COMMENT",
  uploadFile: "UPLOAD_FILE",
});

export const INVOICE_ACTION_TYPE_ENUM = Enum({
  create: "CREATE_INVOICE",
  accept: "ACCEPT_INVOICE",
  revise: "REVISE_INVOICE",
  sent: "SENT_INVOICE",
  paid: "PAID_INVOICE",
  delete: "DELETE_INVOICE",
  remind24: "REMIND_INVOICE_24",
  remind72: "REMIND_INVOICE_72",
});

export const COMPANY_ACTION_TYPE_ENUM = Enum({
  INVITE_USER: "INVITE_USER",
  ADD_ADMIN: "ADD_ADMIN",
  REMOVE_USER: "REMOVE_USER",
  DETAILS_CHANGE: "DETAILS_CHANGE",
});

export const OFFER_TOAST_MESSAGE_ENUM = Enum({
  CREATE_OFFER: "You have successfully placed a new offer!",
  OTHER_ACCEPT_OFFER: "You have accepted the offer for",
  OTHER_COUNTER_OFFER: "You have made a counter offer for",
  OTHER_REJECT_OFFER: "You have rejected",
  OTHER_CANCEL_OFFER: "You have cancelled",
  DELIVERY_OFFER: "Offer has successfully been marked as delivered for",
  RECEIVE_OFFER: "You have successfully marked offer as received for",
  EDIT_ARRIVAL_OFFER: "You have successfully edited the arrival report for",
  RECEIVE_PARTIAL_OFFER: "You have successfully create the arrival report for",
  SUBMIT_ARRIVAL_OFFER: "Successfully submitted arrival report",
  SETTLEMENT_OFFER: "You have successfully marked the offer as settled for",
  COMPLETE_OFFER: "You have successfully marked the offer as completed for",
  CREATE_COMMENT: "You have successfully sent a comment!",
  UPLOAD_FILE: "You have successfully uploaded!",
});

export const NOTIFICATION_TYPE_ENUM = Enum({
  SUCCESS: "SUCCESS",
  INFO: "INFO",
  ALERT: "ALERT",
});

export const USER_TOAST_MESSAGE_ENUM = Enum({
  FORGOT_PASS:
    "You have successfully reset your password and can now access the FruPro platform",
  CHANGE_PASS:
    "You have successfully changed your password and can now access the FruPro Platform",
  DETAILS_CHANGED: "You have successfully updated your details.",
});

export const MY_PRODUCT_TOAST_MESSAGE_ENUM = Enum({
  CREATE_PRODUCE: "Your product has been added to your store!",
  EDIT_PRODUCE: "You have successfully edited your product!",
  DELETE_PRODUCE: "You have successfully deleted your product!",
  LIST_AS_UNAVAILABLE:
    "Your product is no longer listed on the FruPro platform.",
  LIST_FOR_DONATION:
    "Your product details have been sent to the nearest donation centre and they will be in touch with you shortly.",
  LIST_AS_AVAILABLE: "You have succesfully listed your product as available!",
});

export const ACCEPTED_IMAGE_EXTENSION = ".jpeg, .jpg, .png, .gif";

export const COMPANY_STATUS_ENUM = Enum({
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECT: "REJECT",
  VERIFIED: "VERIFIED",
});

export const API_404_ERROR_MESSAGES_ITEMS = [
  "Produce not found.",
  "Produce unavailable.",
];

export const NOTIFY_MESSAGE_TYPE_ENUM = Enum({
  TEXT: "TEXT",
  PRODUCT_ATTACHMENT: "PRODUCE_ATTACHMENT",
  PHOTO: "PHOTO",
  FILE: "FILE",
  AUDIO: "AUDIO",
  VIDEO: "VIDEO",
});

export const SEARCH_PRODUCES_PARAMS = [
  "name",
  // "status",
  "userIds",
  "subProductTypeIds",
  "countryOrigin",
  "countryCode",
  "countryState",
  "countryCity",
  "locationCountry",
  "locationState",
  "locationCity",
  "priceIndicationFrom",
  "priceIndicationTo",
  "productTypeIds",
  "subProductTypeId",
  "devOrCol",
  "timelineFrom",
  "timelineTo",
  "quantityFrom",
  "quantityTo",
  "companyName",
  "companySpecialityIds",
  "companyTypeIds",
  "categories",
];

export const ARRAY_PARAMS = [
  "subProductTypeIds[]",
  "productTypeIds[]",
  "companySpecialityIds[]",
  "companyTypeIds[]",
  "categories[]",
];

export const OFFER_TYPE_ENUM = Enum({
  normal: "NORMAL",
  allocating: "ALLOCATING",
  fixedPrice: "FIXED_PRICE",
});

export const PRODUCE_STATUS = Enum({
  AVAILABLE: "Available",
  SCHEDULE: "Schedule",
  UNAVAILABLE: "Unavailable",
});

export const PAGE_DISPLAY_LOG_ENUM = Enum({
  PRODUCT_LISTING: "product_listing",
  USER_PROFILE: "user_profile",
  COMPANY_PROFILE: "company_profile",
  CONFIDENTIAL_INFO: "confidential_info",
  STORE: "store",
});

export const ACTION_LOG_ENUM = Enum({
  ADD_TO_CART: "add_to_cart",
  LOGIN: "web_login",
});

export const SEARCH_TYPE_ENUM = Enum({
  OFFER: "OFFER",
  DEMANDS: "DEMANDS",
});

export const EXPORT_IMPORT_FILE_TYPE_EXTENSION_ENUM = Enum({
  EXCEL: ".xlsx",
  CSV: ".csv",
});

export const CONNECTION_STATUS_ENUM = Enum({
  NOT_REQUEST: 0,
  REQUESTED: 1,
  PENDING: 2,
  ACCEPTED: 3,
});

export const DEFAULT_EXPORT_FILE_NAME = "product-listings";

export const CONTACT_MESSAGE =
  "Hello, I have seen your demanded product listing. I have a product listing which is similar to what you're looking for. Please feel free to make an offer or reply if you are interested.";

export const COMPANY_TRADING_TYPE_ENUM = Enum({
  buyer: "Buyer",
  seller: "Seller",
  buyer_n_seller: "Buyer & Seller",
});

export const COMPANY_TRADING_TYPES = ["Buyer", "Seller", "Buyer & Seller"];

export const SHOW_AS_ENUM = Enum({
  PRODUCES: "Products",
  DIRECTORY: "Directory",
  USERS: "Connections",
});
