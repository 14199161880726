import { createSlice } from "@reduxjs/toolkit";
import { authStorage } from "services/authStorage";
import {
  signin as signinService,
  signup as signupService,
  signout as signoutService,
} from "services/auth";
import Router from "next/router";
import { getMe } from "./../services/auth";

export const slice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    company:null,
    isCompanyLoading:false,
    isLoggingIn: false,
    isLoggingUp: false,
  },
  reducers: {
    signingIn(state, action) {
      state.isLoggingIn = action.payload;
    },
    companyLoaded(state, action) {
      state.isCompanyLoading = action.payload;
    },
    signingUp(state, action) {
      state.isLoggingUp = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    logOut(state) {
      state.user = null;
      authStorage.destroy();
      localStorage.removeItem('amityAccessToken')
    },
  },
});

export const { signingIn, signingUp, setUser, logOut,setCompany,companyLoaded } = slice.actions;
export const signin = (email, password, callback) => async (dispatch) => {
  try {
    dispatch(signingIn(true));
    const user = await signinService(email, password);
    dispatch(setUser(user));
    if (user?.isVerified && callback) {
      callback();
    }
    if (user?.isVerified) {
      if (user?.companyId) {
        Router.push("/");
      } else {
        Router.push("/company-registration");
      }
    } else {
      Router.push("/sign-up");
    }
  } finally {
    dispatch(signingIn(false));
  }
};

export const signup = (data) => async (dispatch) => {
  const response = await signupService(data);
  const { user, isSuccess, errorCode } = response || {};

  if (isSuccess) {
    dispatch(
      setUser({
        ...user,
        password: data?.password,
        confirmPassword: data?.confirmPassword,
      })
    );
    return {
      user,
      isSuccess,
    };
  } else {
    return { errorCode };
  }
};

export const signout = () => async (dispatch) => {
  try {
 
    await signoutService();
    dispatch(logOut());
  } catch (e) {
  } 
};

export const getUserInfo = () => async (dispatch) => {
  try {
    const { data } = await getMe();
    dispatch(setUser(data));
  } catch (e) {
    console.error(e);
  }
};
export default slice.reducer;
