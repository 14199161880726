export default function Enum(data) {
  if (Array.isArray(data)) data = arrayToObject(data);
  data.lookup = lookup(data);
  data.map = map(data);
  return data;
}

const arrayToObject = (array) => {
  const obj = {};
  array.forEach((item, index) => (obj[item] = index));
  return obj;
};

const map = (data) => {
  const enums = [];
  const lookup = data.lookup;
  for (const key in lookup)
    if (lookup.hasOwnProperty(key)) {
      const value = lookup[key];
      enums.push({ key, value });
    }
  return (transform) => enums.map(transform);
};

const lookup = (data) => {
  const lookup = {};
  for (const key in data)
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      lookup[value] = key;
    }
  return lookup;
};
